import React, { useState, useEffect, useCallback } from 'react';
import { useProjetoContext } from '../contexts/ProjetoContext';
import api from '../api/api';
import './ControleEntregaveis.css';
import ObservacoesSidebar from './ObservacoesSidebar';

function ControleEntregaveis() {
  const { projetoSelecionado } = useProjetoContext();
  const [novaFase, setNovaFase] = useState('');
  const [novaSubetapa, setNovaSubetapa] = useState('');
  const [novaAtividade, setNovaAtividade] = useState('');
  const [editando, setEditando] = useState(null);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState(null);
  const [colapsados, setColapsados] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [atividadeSelecionada, setAtividadeSelecionada] = useState(null);
  const [entregaveis, setEntregaveis] = useState([]);
  const [novaSubatividade, setNovaSubatividade] = useState('');
  const [atividadesComSubatividades, setAtividadesComSubatividades] = useState(new Set());

  const carregarEntregaveis = useCallback(async () => {
    try {
      setCarregando(true);
      console.log('Carregando entregáveis do projeto:', projetoSelecionado._id);
      const response = await api.get(`/entregaveis/projeto/${projetoSelecionado._id}`);
      console.log('Resposta da API:', response.data);
      setEntregaveis(Array.isArray(response.data) ? response.data : []);
      setCarregando(false);
    } catch (error) {
      console.error('Erro ao carregar entregáveis:', error);
      setErro('Falha ao carregar entregáveis. Por favor, tente novamente.');
      setCarregando(false);
      setEntregaveis([]);
    }
  }, [projetoSelecionado]);

  useEffect(() => {
    if (projetoSelecionado) {
      carregarEntregaveis();
    } else {
      setCarregando(false);
    }
  }, [projetoSelecionado, carregarEntregaveis]);

  const handleAdicionarFase = async () => {
    if (!projetoSelecionado) {
      setErro('Nenhum projeto selecionado');
      return;
    }
    
    if (!novaFase.trim()) {
      setErro('O nome da fase não pode estar vazio');
      return;
    }
    
    try {
      const response = await api.post(`/entregaveis/projeto/${projetoSelecionado._id}`, { 
        nome: novaFase.trim() 
      });

      setEntregaveis(prev => [...prev, response.data]);
      setNovaFase('');
      setErro(null);
    } catch (error) {
      console.error('Erro ao adicionar fase:', error);
      setErro(`Falha ao adicionar fase: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleAdicionarSubetapa = async (entregavelId) => {
    if (!novaSubetapa.trim()) {
      setErro('O nome da subetapa não pode estar vazio');
      return;
    }

    try {
      const response = await api.post(`/entregaveis/${entregavelId}/subetapas`, { 
        nome: novaSubetapa.trim() 
      });
      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setNovaSubetapa('');
      setErro(null);
    } catch (error) {
      console.error('Erro ao adicionar subetapa:', error);
      setErro('Falha ao adicionar subetapa. Por favor, tente novamente.');
    }
  };

  const handleAdicionarAtividade = async (entregavelId, subetapaId) => {
    if (!novaAtividade.trim()) {
      setErro('O nome da atividade não pode estar vazio');
      return;
    }

    try {
      const response = await api.post(
        `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades`, 
        { nome: novaAtividade.trim() }
      );
      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setNovaAtividade('');
      setErro(null);
    } catch (error) {
      console.error('Erro ao adicionar atividade:', error);
      setErro('Falha ao adicionar atividade. Por favor, tente novamente.');
    }
  };

  const handleExcluir = async (tipo, id, parentId = null) => {
    const mensagem = `Tem certeza que deseja excluir ${
      tipo === 'fase' ? 'esta fase e todas as suas subetapas e atividades' :
      tipo === 'subetapa' ? 'esta subetapa e todas as suas atividades' :
      'esta atividade'
    }?`;

    if (!window.confirm(mensagem)) return;

    try {
      let url;
      if (tipo === 'fase') {
        url = `/entregaveis/${id}`;
      } else if (tipo === 'subetapa') {
        url = `/entregaveis/${parentId}/subetapas/${id}`;
      } else if (tipo === 'atividade') {
        const { entregavelId, subetapaId } = parentId;
        url = `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${id}`;
      }

      const response = await api.delete(url);
      
      if (tipo === 'fase') {
        setEntregaveis(prev => prev.filter(e => e._id !== id));
      } else {
        setEntregaveis(response.data);
      }
      setErro(null);
    } catch (error) {
      console.error(`Erro ao excluir ${tipo}:`, error);
      setErro(`Falha ao excluir ${tipo}. Por favor, tente novamente.`);
    }
  };

  const handleEditar = (tipo, id, nome, parentId = null) => {
    if (!nome.trim()) {
      setErro('O nome não pode estar vazio');
      return;
    }
    setEditando({ tipo, id, nome, parentId });
  };

  const handleSalvarEdicao = async () => {
    if (!editando || !editando.nome.trim()) {
      setErro('O nome não pode estar vazio');
      return;
    }

    try {
      let url;
      if (editando.tipo === 'fase') {
        url = `/entregaveis/${editando.id}`;
      } else if (editando.tipo === 'subetapa') {
        url = `/entregaveis/${editando.parentId}/subetapas/${editando.id}`;
      } else if (editando.tipo === 'atividade') {
        const { entregavelId, subetapaId } = editando.parentId;
        url = `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${editando.id}`;
      }

      const response = await api.put(url, { nome: editando.nome.trim() });
      setEntregaveis(prevEntregaveis => {
        if (editando.tipo === 'fase') {
          return prevEntregaveis.map(e => e._id === editando.id ? response.data : e);
        }
        return response.data;
      });
      setEditando(null);
      setErro(null);
    } catch (error) {
      console.error(`Erro ao editar ${editando.tipo}:`, error);
      setErro(`Falha ao editar ${editando.tipo}. Por favor, tente novamente.`);
    }
  };

  const handleStatusChange = async (atividade, entregavelId, subetapaId) => {
    try {
      const response = await api.put(
        `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${atividade._id}`,
        { status: atividade.status }
      );
      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setErro(null);
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      setErro('Falha ao atualizar status. Por favor, tente novamente.');
    }
  };

  const handlePorcentagemChange = async (atividade, entregavelId, subetapaId, valor) => {
    const porcentagem = parseFloat(valor);
    if (isNaN(porcentagem) || porcentagem < 0 || porcentagem > 100) {
      setErro('A porcentagem deve ser um número entre 0 e 100');
      return;
    }

    try {
      const response = await api.put(
        `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${atividade._id}`,
        { porcentagem }
      );
      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setErro(null);
    } catch (error) {
      console.error('Erro ao atualizar porcentagem:', error);
      setErro('Falha ao atualizar porcentagem. Por favor, tente novamente.');
    }
  };

  const handleSaveObservacoes = async (observacoes) => {
    if (!atividadeSelecionada) return;

    try {
      const { _id: atividadeId, entregavelId, subetapaId } = atividadeSelecionada;
      const response = await api.put(
        `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${atividadeId}/observacoes`,
        { observacoes }
      );
      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setSidebarOpen(false);
      setErro(null);
    } catch (error) {
      console.error('Erro ao salvar observações:', error);
      setErro('Falha ao salvar observações. Por favor, tente novamente.');
    }
  };

  const toggleColapso = (id) => {
    setColapsados(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleObservacoesClick = (atividade, faseId, subetapaId) => {
    setAtividadeSelecionada({ ...atividade, faseId, subetapaId });
    setSidebarOpen(true);
  };

  const handleHabilitarSubatividades = (atividadeId) => {
    console.log('Habilitando subatividades para:', atividadeId);
    setAtividadesComSubatividades(prev => {
      const novoSet = new Set(prev);
      novoSet.add(atividadeId);
      return novoSet;
    });
  };

  const handleCancelarSubatividades = (atividadeId) => {
    console.log('Cancelando subatividades para:', atividadeId);
    setAtividadesComSubatividades(prev => {
      const novoSet = new Set(prev);
      novoSet.delete(atividadeId);
      return novoSet;
    });
    setNovaSubatividade(''); // Limpa o input ao cancelar
  };

  const handleAdicionarSubatividade = async (entregavelId, subetapaId, atividadeId) => {
    console.log('Tentando adicionar subatividade:', {
      entregavelId,
      subetapaId,
      atividadeId,
      nome: novaSubatividade
    });

    if (!novaSubatividade.trim()) {
      setErro('O nome da subatividade não pode estar vazio');
      return;
    }

    try {
      const response = await api.post(
        `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${atividadeId}/subatividades`,
        { nome: novaSubatividade.trim() }
      );

      console.log('Resposta da API:', response.data);

      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setNovaSubatividade('');
      handleCancelarSubatividades(atividadeId);
      setErro(null);
    } catch (error) {
      console.error('Erro ao adicionar subatividade:', error);
      setErro('Falha ao adicionar subatividade. Por favor, tente novamente.');
    }
  };

  const handleExcluirSubatividade = async (entregavelId, subetapaId, atividadeId, subatividadeId) => {
    if (!window.confirm('Tem certeza que deseja excluir esta subatividade?')) return;

    try {
      const response = await api.delete(
        `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${atividadeId}/subatividades/${subatividadeId}`
      );
      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setErro(null);
    } catch (error) {
      console.error('Erro ao excluir subatividade:', error);
      setErro('Falha ao excluir subatividade. Por favor, tente novamente.');
    }
  };

  const handleSubatividadeStatusChange = async (subatividade, entregavelId, subetapaId, atividadeId) => {
    try {
      const response = await api.put(
        `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${atividadeId}/subatividades/${subatividade._id}`,
        { status: subatividade.status }
      );
      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setErro(null);
    } catch (error) {
      console.error('Erro ao atualizar status da subatividade:', error);
      setErro('Falha ao atualizar status da subatividade. Por favor, tente novamente.');
    }
  };

  const handleSubatividadePorcentagemChange = async (subatividade, entregavelId, subetapaId, atividadeId, valor) => {
    const porcentagem = parseFloat(valor);
    if (isNaN(porcentagem) || porcentagem < 0 || porcentagem > 100) {
      setErro('A porcentagem deve ser um número entre 0 e 100');
      return;
    }

    try {
      const response = await api.put(
        `/entregaveis/${entregavelId}/subetapas/${subetapaId}/atividades/${atividadeId}/subatividades/${subatividade._id}`,
        { porcentagem }
      );
      setEntregaveis(prevEntregaveis => {
        return prevEntregaveis.map(entregavel => 
          entregavel._id === entregavelId ? response.data : entregavel
        );
      });
      setErro(null);
    } catch (error) {
      console.error('Erro ao atualizar porcentagem da subatividade:', error);
      setErro('Falha ao atualizar porcentagem da subatividade. Por favor, tente novamente.');
    }
  };

  if (!projetoSelecionado) {
    return <div>Por favor, selecione um projeto na barra lateral.</div>;
  }

  if (erro) {
    return <div>Erro: {erro}</div>;
  }

  if (carregando) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="controle-entregaveis">
      <h1>Controle de Entregáveis</h1>
      
      {projetoSelecionado && (
        <div className="projeto-detalhes">
          <h2>{projetoSelecionado.nome}</h2>
          <p>Cliente: {projetoSelecionado.nomeCliente}</p>
          <p>Status: {projetoSelecionado.status}</p>
          
          <div className="adicionar-item">
            <input
              type="text"
              value={novaFase}
              onChange={(e) => setNovaFase(e.target.value)}
              placeholder="Nome da nova fase"
            />
            <button onClick={handleAdicionarFase}>Adicionar Fase</button>
          </div>

          <table className="entregaveis-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>TIPO</th>
                <th>FASE / TAREFA</th>
                <th>STATUS</th>
                <th>%</th>
                <th>AÇÕES</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(entregaveis) && entregaveis.map((entregavel) => (
                <React.Fragment key={entregavel._id}>
                  <tr className="fase-row">
                    <td>{entregavel.numero}</td>
                    <td><strong>Fase</strong></td>
                    <td>
                      <button 
                        onClick={() => toggleColapso(entregavel._id)} 
                        className={`toggle-collapse ${colapsados[entregavel._id] ? 'collapsed' : ''}`}
                      />
                      <strong>{entregavel.nome}</strong>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <button onClick={() => handleEditar('fase', entregavel._id, entregavel.nome)}>Editar</button>
                      <button onClick={() => handleExcluir('fase', entregavel._id)}>Excluir</button>
                    </td>
                  </tr>
                  {!colapsados[entregavel._id] && (
                    <>
                      {entregavel.subetapas.map((subetapa) => (
                        <React.Fragment key={subetapa._id}>
                          <tr className="subetapa-row">
                            <td>{subetapa.numero}</td>
                            <td><strong>Subetapa</strong></td>
                            <td>
                              <button 
                                onClick={() => toggleColapso(subetapa._id)} 
                                className={`toggle-collapse ${colapsados[subetapa._id] ? 'collapsed' : ''}`}
                              />
                              <strong>{subetapa.nome}</strong>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              <button onClick={() => handleEditar('subetapa', subetapa._id, subetapa.nome, entregavel._id)}>Editar</button>
                              <button onClick={() => handleExcluir('subetapa', subetapa._id, entregavel._id)}>Excluir</button>
                            </td>
                          </tr>
                          {!colapsados[subetapa._id] && (
                            <React.Fragment>
                              {subetapa.atividades.map((atividade) => (
                                <React.Fragment key={atividade._id}>
                                  <tr className={`atividade-row ${atividade.subatividades?.length > 0 ? 'has-subatividades' : ''}`}>
                                    <td>{atividade.numero}</td>
                                    <td>Atividade</td>
                                    <td>
                                      <div className="atividade-nome">
                                        {atividade.nome}
                                        <button 
                                          className="habilitar-subatividades"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleHabilitarSubatividades(atividade._id);
                                          }}
                                          type="button"
                                          title="Adicionar subatividade"
                                        >
                                          <span>+</span>
                                        </button>
                                        {atividadesComSubatividades.has(atividade._id) && (
                                          <div className="subatividade-inline-form">
                                            <input
                                              type="text"
                                              value={novaSubatividade}
                                              onChange={(e) => setNovaSubatividade(e.target.value)}
                                              placeholder="Nome da nova subatividade"
                                              autoFocus
                                            />
                                            <div className="subatividade-buttons">
                                              <button 
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleAdicionarSubatividade(entregavel._id, subetapa._id, atividade._id);
                                                }}
                                                className="adicionar-subatividade-btn"
                                              >
                                                Adicionar
                                              </button>
                                              <button 
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleCancelarSubatividades(atividade._id);
                                                }}
                                                className="cancelar-subatividade-btn"
                                              >
                                                Cancelar
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <select
                                        value={atividade.status}
                                        onChange={(e) => handleStatusChange(atividade, entregavel._id, subetapa._id)}
                                        disabled={atividade.subatividades?.length > 0}
                                      >
                                        <option value="Não iniciado">Não iniciado</option>
                                        <option value="Pendente">Pendente</option>
                                        <option value="Entrega Parcial">Entrega Parcial</option>
                                        <option value="Entregue">Entregue</option>
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        value={atividade.porcentagem}
                                        onChange={(e) => handlePorcentagemChange(
                                          atividade,
                                          entregavel._id,
                                          subetapa._id,
                                          e.target.value
                                        )}
                                        disabled={atividade.subatividades?.length > 0}
                                      />
                                    </td>
                                    <td>
                                      <div className="acoes-buttons">
                                        <button onClick={() => handleEditar('atividade', atividade._id, atividade.nome, { entregavelId: entregavel._id, subetapaId: subetapa._id })}>Editar</button>
                                        <button onClick={() => handleExcluir('atividade', atividade._id, { entregavelId: entregavel._id, subetapaId: subetapa._id })}>Excluir</button>
                                        <button onClick={() => handleObservacoesClick(atividade, entregavel._id, subetapa._id)}>Observações</button>
                                      </div>
                                    </td>
                                  </tr>
                                  {!colapsados[atividade._id] && atividade.subatividades?.map((subatividade) => (
                                    <tr key={subatividade._id} className="subatividade-row">
                                      <td>{subatividade.numero}</td>
                                      <td>Subatividade</td>
                                      <td>{subatividade.nome}</td>
                                      <td>
                                        <select
                                          value={subatividade.status}
                                          onChange={(e) => {
                                            const novaSubatividade = { ...subatividade, status: e.target.value };
                                            handleSubatividadeStatusChange(novaSubatividade, entregavel._id, subetapa._id, atividade._id);
                                          }}
                                          className="status-select"
                                        >
                                          <option value="Não iniciado">Não iniciado</option>
                                          <option value="Pendente">Pendente</option>
                                          <option value="Entrega Parcial">Entrega Parcial</option>
                                          <option value="Entregue">Entregue</option>
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          value={subatividade.porcentagem}
                                          onChange={(e) => handleSubatividadePorcentagemChange(
                                            subatividade,
                                            entregavel._id,
                                            subetapa._id,
                                            atividade._id,
                                            e.target.value
                                          )}
                                          min="0"
                                          max="100"
                                          step="0.1"
                                          className="porcentagem-input"
                                        />
                                      </td>
                                      <td>
                                        <div className="acoes-buttons">
                                          <button onClick={() => handleEditar('subatividade', subatividade._id, subatividade.nome, { 
                                            entregavelId: entregavel._id, 
                                            subetapaId: subetapa._id, 
                                            atividadeId: atividade._id 
                                          })}>Editar</button>
                                          <button onClick={() => handleExcluirSubatividade(entregavel._id, subetapa._id, atividade._id, subatividade._id)}>Excluir</button>
                                          <button onClick={() => handleObservacoesClick(subatividade, entregavel._id, subetapa._id, atividade._id)}>Observações</button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </React.Fragment>
                              ))}
                              <tr>
                                <td colSpan="6">
                                  <div className="adicionar-atividade">
                                    <input
                                      type="text"
                                      value={novaAtividade}
                                      onChange={(e) => setNovaAtividade(e.target.value)}
                                      placeholder="Nome da nova atividade"
                                    />
                                    <button onClick={() => handleAdicionarAtividade(entregavel._id, subetapa._id)}>
                                      Adicionar Atividade
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="6">
                                  <div className="adicionar-subetapa">
                                    <input
                                      type="text"
                                      value={novaSubetapa}
                                      onChange={(e) => setNovaSubetapa(e.target.value)}
                                      placeholder="Nome da nova subetapa"
                                    />
                                    <button onClick={() => handleAdicionarSubetapa(entregavel._id)}>
                                      Adicionar Subetapa
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {editando && (
        <div className="modal">
          <div className="modal-content">
            <h3>Editar {editando.tipo}</h3>
            <input
              type="text"
              value={editando.nome}
              onChange={(e) => setEditando({...editando, nome: e.target.value})}
            />
            <button onClick={handleSalvarEdicao}>Salvar</button>
            <button onClick={() => setEditando(null)}>Cancelar</button>
          </div>
        </div>
      )}

      <ObservacoesSidebar
        isOpen={sidebarOpen}
        atividade={atividadeSelecionada}
        onClose={() => setSidebarOpen(false)}
        onSave={handleSaveObservacoes}
      />
    </div>
  );
}

export default ControleEntregaveis;