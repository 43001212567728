import React, { useState, useEffect } from 'react';
import { useProjetoContext } from '../contexts/ProjetoContext';
import api from '../api/api';
import './ChecklistVerificacao.css';
import ObservacoesSidebar from './ObservacoesSidebar';

function ChecklistVerificacao() {
  const { projetoSelecionado } = useProjetoContext();
  const [checklist, setChecklist] = useState(null);
  const [novoItem, setNovoItem] = useState('');
  const [editando, setEditando] = useState(null);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState(null);
  const [colapsados, setColapsados] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [verificacaoSelecionada, setVerificacaoSelecionada] = useState(null);
  const [acoes] = useState(['ELABORAÇÃO', 'VERIFICAÇÃO - SOLIBRI', 'VERIFICAÇÃO - AUTOCAD', 'VERIFICAÇÃO - REVIT', 'VERIFICAÇÃO - VISUAL', 'OUTRO']);
  const [responsaveis, setResponsaveis] = useState([]);
  const [novoResponsavel, setNovoResponsavel] = useState('');
  const [disciplinas, setDisciplinas] = useState([]);
  const [verificacoesComSubverificacoes, setVerificacoesComSubverificacoes] = useState(new Set());
  const [novaSubverificacao, setNovaSubverificacao] = useState('');

  useEffect(() => {
    console.log('Estado verificacaoSelecionada atualizado:', {
      verificacao: verificacaoSelecionada,
      sidebarOpen
    });
  }, [verificacaoSelecionada, sidebarOpen]);

  const hasObservacoes = (item) => {
    const temObservacao = Boolean(item.observacoes && item.observacoes.trim() !== '');
    if (temObservacao) {
      console.log('Item COM observação:', {
        id: item._id,
        numero: item.numero,
        nome: item.nome,
        observacoes: item.observacoes
      });
    }
    return temObservacao;
  };

  useEffect(() => {
    if (projetoSelecionado) {
      carregarChecklist();
      carregarResponsaveis();
      setDisciplinas(projetoSelecionado.disciplinas || []);
    } else {
      setCarregando(false);
    }
  }, [projetoSelecionado]);

  const carregarChecklist = async () => {
    try {
      setCarregando(true);
      console.log('Carregando checklist do projeto:', projetoSelecionado._id);
      const response = await api.get(`/checklists/${projetoSelecionado._id}`);
      
      // Log apenas das verificações que têm observações
      response.data.fases.forEach(fase => {
        fase.subetapas.forEach(subetapa => {
          subetapa.verificacoes.forEach(verificacao => {
            if (verificacao.observacoes) {
              console.log('Verificação com observação:', {
                id: verificacao._id,
                numero: verificacao.numero,
                nome: verificacao.nome,
                observacoes: verificacao.observacoes
              });
            }
            verificacao.subverificacoes?.forEach(sub => {
              if (sub.observacoes) {
                console.log('Subverificação com observação:', {
                  id: sub._id,
                  numero: sub.numero,
                  nome: sub.nome,
                  observacoes: sub.observacoes,
                  verificacaoPai: verificacao.numero
                });
              }
            });
          });
        });
      });
      
      setChecklist(response.data);
      setCarregando(false);
    } catch (error) {
      console.error('Erro ao carregar checklist:', error);
      if (error.response?.status === 401) {
        setErro('Sessão expirada. Por favor, faça login novamente.');
      } else {
        setErro('Falha ao carregar checklist. Por favor, tente novamente.');
      }
      setCarregando(false);
    }
  };

  const carregarResponsaveis = async () => {
    try {
      const response = await api.get('/responsaveis');
      setResponsaveis(response.data);
    } catch (error) {
      console.error('Erro ao carregar responsáveis:', error);
      setErro('Falha ao carregar responsáveis. Por favor, tente novamente.');
    }
  };

  const handleAdicionarItem = async (tipo, parentId = null) => {
    if (!projetoSelecionado || !novoItem.trim()) return;
    
    try {
      let url;
      const item = { 
        nome: novoItem,
        disciplina: '',
        acao: 'ELABORAÇÃO',
        status: 'Não iniciado',
        porcentagem: 0,
        dataPrevista: new Date()
      };
      
      if (tipo === 'fase') {
        url = `/checklists/${projetoSelecionado._id}/fases`;
      } else if (tipo === 'subetapa') {
        url = `/checklists/${projetoSelecionado._id}/fases/${parentId}/subetapas`;
      } else if (tipo === 'verificacao') {
        url = `/checklists/${projetoSelecionado._id}/fases/${parentId.faseId}/subetapas/${parentId.subetapaId}/verificacoes`;
      }

      console.log('Enviando requisição:', { url, item });
      const response = await api.post(url, item);
      console.log('Resposta:', response.data);
      
      setChecklist(response.data);
      setNovoItem('');
    } catch (error) {
      console.error('Erro detalhado ao adicionar verificacao:', error.response?.data || error);
      setErro(`Falha ao adicionar ${tipo}. ${error.response?.data?.message || error.message}`);
    }
  };

  const handleEditar = (tipo, id, nome, parentId = null) => {
    setEditando({ tipo, id, nome, parentId });
  };

  const handleSalvarEdicao = async () => {
    if (!editando) return;

    try {
      let url;
      if (editando.tipo === 'fase') {
        url = `/checklists/${projetoSelecionado._id}/fases/${editando.id}`;
      } else if (editando.tipo === 'subetapa') {
        url = `/checklists/${projetoSelecionado._id}/fases/${editando.parentId}/subetapas/${editando.id}`;
      } else if (editando.tipo === 'verificacao') {
        url = `/checklists/${projetoSelecionado._id}/fases/${editando.parentId.faseId}/subetapas/${editando.parentId.subetapaId}/verificacoes/${editando.id}`;
      }

      const response = await api.put(url, { nome: editando.nome });
      setChecklist(response.data);
      setEditando(null);
    } catch (error) {
      console.error(`Erro ao editar ${editando.tipo}:`, error);
      setErro(`Falha ao editar ${editando.tipo}. Por favor, tente novamente.`);
    }
  };

  const handleExcluir = async (tipo, id, parentId = null) => {
    if (!window.confirm(`Tem certeza que deseja excluir este(a) ${tipo}?`)) return;

    try {
      let url;
      if (tipo === 'fase') {
        url = `/checklists/${projetoSelecionado._id}/fases/${id}`;
      } else if (tipo === 'subetapa') {
        url = `/checklists/${projetoSelecionado._id}/fases/${parentId}/subetapas/${id}`;
      } else if (tipo === 'verificacao') {
        url = `/checklists/${projetoSelecionado._id}/fases/${parentId.faseId}/subetapas/${parentId.subetapaId}/verificacoes/${id}`;
      }

      const response = await api.delete(url);
      setChecklist(response.data);
    } catch (error) {
      console.error(`Erro ao excluir ${tipo}:`, error);
      setErro(`Falha ao excluir ${tipo}. Por favor, tente novamente.`);
    }
  };

  const toggleColapso = (id) => {
    setColapsados(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleStatusChange = async (verificacaoId, novoStatus, faseId, subetapaId) => {
    try {
      const url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}`;
      const response = await api.put(url, { status: novoStatus });
      setChecklist(response.data);
    } catch (error) {
      console.error(`Erro ao atualizar status da verificação:`, error);
      setErro(`Falha ao atualizar status da verificação. Por favor, tente novamente.`);
    }
  };

  const handleObservacoesClick = (verificacao, faseId, subetapaId, verificacaoPaiId = null) => {
    setSidebarOpen(true);
    setVerificacaoSelecionada({
      _id: verificacao._id,
      nome: verificacao.nome,
      observacoes: verificacao.observacoes,
      numero: verificacao.numero,
      faseId,
      subetapaId,
      verificacaoPaiId: verificacaoPaiId || null
    });
  };

  const handleSaveObservacoes = async (observacoes) => {
    try {
      if (!verificacaoSelecionada) {
        setErro('Nenhuma verificação selecionada');
        return;
      }

      const { _id: verificacaoId, faseId, subetapaId, verificacaoPaiId } = verificacaoSelecionada;
      
      let url;
      if (verificacaoPaiId) {
        url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoPaiId}/subverificacoes/${verificacaoId}/observacoes`;
      } else {
        url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}/observacoes`;
      }

      const response = await api.put(url, { observacoes });
      setChecklist(response.data);
      setSidebarOpen(false);
      setVerificacaoSelecionada(null);
      setErro(null);
    } catch (error) {
      console.error('Erro ao salvar observações:', error);
      setErro('Falha ao salvar observações. Por favor, tente novamente.');
    }
  };

  const adicionarResponsavel = async () => {
    if (novoResponsavel.trim()) {
      try {
        await api.post('/responsaveis', { nome: novoResponsavel });
        setNovoResponsavel('');
        carregarResponsaveis();
      } catch (error) {
        console.error('Erro ao adicionar responsável:', error);
      }
    }
  };

  const handleResponsavelChange = async (verificacaoId, novoResponsavel) => {
    try {
      await api.put(`/verificacoes/${verificacaoId}`, { responsavel: novoResponsavel });
      setChecklist(prevChecklist => {
        const novoChecklist = { ...prevChecklist };
        novoChecklist.fases = novoChecklist.fases.map(fase => ({
          ...fase,
          subetapas: fase.subetapas.map(subetapa => ({
            ...subetapa,
            verificacoes: subetapa.verificacoes.map(v => 
              v._id === verificacaoId ? { ...v, responsavel: novoResponsavel } : v
            )
          }))
        }));
        return novoChecklist;
      });
    } catch (error) {
      console.error('Erro ao atualizar o responsável:', error);
    }
  };

  const handleAcaoChange = async (verificacaoId, novaAcao, faseId, subetapaId) => {
    try {
      console.log('Atualizando ação:', {
        verificacaoId,
        novaAcao,
        faseId,
        subetapaId,
        projetoId: projetoSelecionado._id
      });

      const url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}`;
      console.log('URL da requisição:', url);

      const response = await api.put(url, { 
        acao: novaAcao 
      });
      
      console.log('Resposta da API:', response.data);
      
      if (response.data) {
        setChecklist(response.data);
      } else {
        throw new Error('Resposta vazia do servidor');
      }
    } catch (error) {
      console.error('Erro detalhado ao atualizar ação:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setErro(`Falha ao atualizar ação da verificação. ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDisciplinaChange = async (verificacaoId, novaDisciplina, faseId, subetapaId) => {
    try {
      const response = await api.put(
        `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}`,
        { disciplina: novaDisciplina }
      );
      setChecklist(response.data);
    } catch (error) {
      console.error('Erro ao atualizar disciplina:', error);
      setErro('Falha ao atualizar disciplina. Por favor, tente novamente.');
    }
  };

  const handleHabilitarSubverificacoes = (verificacaoId) => {
    setVerificacoesComSubverificacoes(prev => {
      const novoSet = new Set(prev);
      novoSet.add(verificacaoId);
      return novoSet;
    });
  };

  const handleCancelarSubverificacoes = (verificacaoId) => {
    setVerificacoesComSubverificacoes(prev => {
      const novoSet = new Set(prev);
      novoSet.delete(verificacaoId);
      return novoSet;
    });
  };

  const handleAdicionarSubverificacao = async (faseId, subetapaId, verificacaoId) => {
    if (!novaSubverificacao.trim()) {
      setErro('O nome da subverificação não pode estar vazio');
      return;
    }

    try {
      console.log('Enviando requisição para adicionar subverificação:', {
        projetoId: projetoSelecionado._id,
        faseId,
        subetapaId,
        verificacaoId,
        nome: novaSubverificacao
      });

      const url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}/subverificacoes`;
      
      console.log('URL da requisição:', url);
      
      const response = await api.post(url, { 
        nome: novaSubverificacao.trim() 
      });

      console.log('Resposta do servidor:', response.data);
      
      setChecklist(response.data);
      setNovaSubverificacao('');
      setErro(null);
      
      // Fechar o formulário de subverificação usando o state correto
      setVerificacoesComSubverificacoes(prev => {
        const novoSet = new Set(prev);
        novoSet.delete(verificacaoId);
        return novoSet;
      });
    } catch (error) {
      console.error('Erro detalhado ao adicionar subverificação:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setErro('Falha ao adicionar subverificação. Por favor, tente novamente.');
    }
  };

  const handleExcluirSubverificacao = async (faseId, subetapaId, verificacaoId, subverificacaoId) => {
    if (!window.confirm('Tem certeza que deseja excluir esta subverificação?')) return;

    try {
      const response = await api.delete(
        `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}/subverificacoes/${subverificacaoId}`
      );
      setChecklist(response.data);
      setErro(null);
    } catch (error) {
      console.error('Erro ao excluir subverificação:', error);
      setErro('Falha ao excluir subverificação. Por favor, tente novamente.');
    }
  };

  const handleSubverificacaoStatusChange = async (subverificacao, faseId, subetapaId, verificacaoId) => {
    try {
      const response = await api.put(
        `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}/subverificacoes/${subverificacao._id}`,
        { status: subverificacao.status }
      );
      setChecklist(response.data);
      setErro(null);
    } catch (error) {
      console.error('Erro ao atualizar status da subverificação:', error);
      setErro('Falha ao atualizar status da subverificação. Por favor, tente novamente.');
    }
  };

  const handleDataChange = async (verificacaoId, novaData, faseId, subetapaId, verificacaoPaiId = null, isSubverificacao = false) => {
    try {
      let url;
      if (isSubverificacao) {
        url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoPaiId}/subverificacoes/${verificacaoId}`;
      } else {
        url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}`;
      }

      const response = await api.put(url, { 
        dataPrevista: new Date(novaData) 
      });
      
      setChecklist(response.data);
      setErro(null);
    } catch (error) {
      console.error('Erro ao atualizar data:', error);
      setErro('Falha ao atualizar data. Por favor, tente novamente.');
    }
  };

  const handlePorcentagemChange = async (verificacaoId, novaPorcentagem, faseId, subetapaId, verificacaoPaiId = null, isSubverificacao = false) => {
    try {
      // Validar porcentagem
      const porcentagem = parseInt(novaPorcentagem);
      if (isNaN(porcentagem) || porcentagem < 0 || porcentagem > 100) {
        setErro('A porcentagem deve ser um número entre 0 e 100');
        return;
      }

      let url;
      if (isSubverificacao) {
        url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoPaiId}/subverificacoes/${verificacaoId}`;
      } else {
        url = `/checklists/${projetoSelecionado._id}/fases/${faseId}/subetapas/${subetapaId}/verificacoes/${verificacaoId}`;
      }

      const response = await api.put(url, { 
        porcentagem: porcentagem 
      });
      
      setChecklist(response.data);
      setErro(null);
    } catch (error) {
      console.error('Erro ao atualizar porcentagem:', error);
      setErro('Falha ao atualizar porcentagem. Por favor, tente novamente.');
    }
  };

  const renderTableRow = (item) => {
    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td className={`col-${item.tipo.toLowerCase()}`}>{item.tipo}</td>
        <td>{item.disc}</td>
        <td>{item.fase}</td>
        <td>{item.acao}</td>
        <td>{item.responsavel}</td>
        <td>
          {item.dataPrevista ? 
            new Date(item.dataPrevista).toLocaleDateString() : 
            '-'
          }
        </td>
        <td>{item.status}</td>
        <td className="col-porcentagem">{item.porcentagem}%</td>
        <td className="col-acoes">
          <button className="btn btn-primary btn-sm">Editar</button>
          <button className="btn btn-danger btn-sm">Excluir</button>
          {item.tipo === 'Verificação' && (
            <button className="btn btn-info btn-sm">Observações</button>
          )}
        </td>
      </tr>
    );
  };

  if (!projetoSelecionado) {
    return <div>Por favor, selecione um projeto na barra lateral.</div>;
  }

  if (erro) {
    return <div>Erro: {erro}</div>;
  }

  if (carregando) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="checklist-verificacao">
      <h1>Checklist de Verificação</h1>
      
      {checklist && (
        <div className="checklist-detalhes">
          <div className="adicionar-item">
            <input
              type="text"
              value={novoItem}
              onChange={(e) => setNovoItem(e.target.value)}
              placeholder="Nome da nova fase"
            />
            <button onClick={() => handleAdicionarItem('fase')}>Adicionar Fase</button>
          </div>

          <table className="checklist-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Tipo</th>
                <th>Disc</th>
                <th>Fase / Tarefa</th>
                <th>Ação</th>
                <th>Responsável</th>
                <th>Data</th>
                <th>Status</th>
                <th>%</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {checklist.fases.map((fase) => (
                <React.Fragment key={fase._id}>
                  <tr className="fase-row">
                    <td>{fase.numero}</td>
                    <td>Fase</td>
                    <td>-</td>
                    <td>
                      <button 
                        onClick={() => toggleColapso(fase._id)} 
                        className={`toggle-collapse ${colapsados[fase._id] ? 'collapsed' : ''}`}
                      />
                      <strong>{fase.nome}</strong>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{fase.porcentagem?.toFixed(2)}%</td>
                    <td className="col-acoes">
                      <div className="acoes-buttons">
                        <button onClick={() => handleEditar('fase', fase._id, fase.nome)}>Editar</button>
                        <button onClick={() => handleExcluir('fase', fase._id)} className="excluir">Excluir</button>
                      </div>
                    </td>
                  </tr>
                  {!colapsados[fase._id] && (
                    <React.Fragment>
                      {fase.subetapas.map((subetapa) => (
                        <React.Fragment key={subetapa._id}>
                          <tr className="subetapa-row">
                            <td>{subetapa.numero}</td>
                            <td>Subetapa</td>
                            <td>-</td>
                            <td>
                              <button 
                                onClick={() => toggleColapso(subetapa._id)} 
                                className={`toggle-collapse ${colapsados[subetapa._id] ? 'collapsed' : ''}`}
                              />
                              <strong>{subetapa.nome}</strong>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{subetapa.porcentagem?.toFixed(2)}%</td>
                            <td className="col-acoes">
                              <div className="acoes-buttons">
                                <button onClick={() => handleEditar('subetapa', subetapa._id, subetapa.nome, fase._id)}>Editar</button>
                                <button onClick={() => handleExcluir('subetapa', subetapa._id, fase._id)} className="excluir">Excluir</button>
                              </div>
                            </td>
                          </tr>
                          {!colapsados[subetapa._id] && (
                            <React.Fragment>
                              {subetapa.verificacoes.map((verificacao) => (
                                <React.Fragment key={verificacao._id}>
                                  <tr className={`verificacao-row ${verificacao.subverificacoes?.length > 0 ? 'has-subverificacoes' : ''}`}>
                                    <td>{verificacao.numero}</td>
                                    <td className={hasObservacoes(verificacao) ? 'has-observacoes' : ''}>Verificação</td>
                                    <td>
                                      <select
                                        value={verificacao.disciplina || ''}
                                        onChange={(e) => handleDisciplinaChange(verificacao._id, e.target.value, fase._id, subetapa._id)}
                                        disabled={verificacao.subverificacoes?.length > 0}
                                      >
                                        <option value="">Selecione...</option>
                                        {disciplinas.map((disc) => (
                                          <option key={disc._id} value={disc.codigo}>
                                            {disc.codigo}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <div className="verificacao-nome">
                                        {verificacao.nome}
                                        <button 
                                          className="habilitar-subverificacoes"
                                          onClick={() => handleHabilitarSubverificacoes(verificacao._id)}
                                          title="Clique aqui para criar subverificações"
                                        >
                                          <span>+</span>
                                        </button>
                                        {verificacoesComSubverificacoes.has(verificacao._id) && (
                                          <div className="subverificacao-inline-form">
                                            <input
                                              type="text"
                                              value={novaSubverificacao}
                                              onChange={(e) => setNovaSubverificacao(e.target.value)}
                                              placeholder="Nome da nova subverificação"
                                            />
                                            <div className="subverificacao-buttons">
                                              <button 
                                                onClick={() => handleAdicionarSubverificacao(fase._id, subetapa._id, verificacao._id)}
                                                className="adicionar-subverificacao-btn"
                                              >
                                                Adicionar Subverificação
                                              </button>
                                              <button 
                                                onClick={() => handleCancelarSubverificacoes(verificacao._id)}
                                                className="cancelar-subverificacao-btn"
                                              >
                                                Cancelar
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <select
                                        value={verificacao.acao}
                                        onChange={(e) => handleAcaoChange(verificacao._id, e.target.value, fase._id, subetapa._id)}
                                        disabled={verificacao.subverificacoes?.length > 0}
                                      >
                                        {acoes.map((acao) => (
                                          <option key={acao} value={acao}>{acao}</option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={verificacao.responsavel || ''}
                                        onChange={(e) => handleResponsavelChange(verificacao._id, e.target.value)}
                                        disabled={verificacao.subverificacoes?.length > 0}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="date"
                                        value={verificacao.dataPrevista ? 
                                          new Date(verificacao.dataPrevista).toISOString().split('T')[0] : 
                                          ''
                                        }
                                        onChange={(e) => handleDataChange(
                                          verificacao._id,
                                          e.target.value,
                                          fase._id,
                                          subetapa._id
                                        )}
                                        disabled={verificacao.subverificacoes?.length > 0}
                                      />
                                    </td>
                                    <td>
                                      <select
                                        value={verificacao.status}
                                        onChange={(e) => handleStatusChange(verificacao._id, e.target.value, fase._id, subetapa._id)}
                                        disabled={verificacao.subverificacoes?.length > 0}
                                      >
                                        <option value="Não iniciado">Não iniciado</option>
                                        <option value="Em andamento">Em andamento</option>
                                        <option value="Paralisado">Paralisado</option>
                                        <option value="Concluído">Concluído</option>
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        min="0"
                                        max="100"
                                        value={verificacao.porcentagem}
                                        onChange={(e) => handlePorcentagemChange(
                                          verificacao._id,
                                          e.target.value,
                                          fase._id,
                                          subetapa._id
                                        )}
                                      />%
                                    </td>
                                    <td className="col-acoes">
                                      <div className="acoes-buttons">
                                        <button onClick={() => handleEditar('verificacao', verificacao._id, verificacao.nome, { faseId: fase._id, subetapaId: subetapa._id })}>Editar</button>
                                        <button onClick={() => handleExcluir('verificacao', verificacao._id, { faseId: fase._id, subetapaId: subetapa._id })} className="excluir">Excluir</button>
                                        <button onClick={() => handleObservacoesClick(
                                          verificacao,
                                          fase._id,
                                          subetapa._id
                                        )} className="btn-observacoes">Observações</button>
                                      </div>
                                    </td>
                                  </tr>
                                  {verificacao.subverificacoes?.map((subverificacao) => (
                                    <tr key={subverificacao._id} className="subverificacao-row">
                                      <td>{subverificacao.numero}</td>
                                      <td className={hasObservacoes(subverificacao) ? 'has-observacoes' : ''}>Subverificação</td>
                                      <td>
                                        <select
                                          value={subverificacao.disciplina || ''}
                                          onChange={(e) => handleDisciplinaChange(
                                            subverificacao._id,
                                            e.target.value,
                                            fase._id,
                                            subetapa._id,
                                            verificacao._id,
                                            true
                                          )}
                                        >
                                          <option value="">Selecione...</option>
                                          {disciplinas.map((disc) => (
                                            <option key={disc._id} value={disc.codigo}>
                                              {disc.codigo}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td>{subverificacao.nome}</td>
                                      <td>
                                        <select
                                          value={subverificacao.acao}
                                          onChange={(e) => handleAcaoChange(
                                            subverificacao._id,
                                            e.target.value,
                                            fase._id,
                                            subetapa._id,
                                            verificacao._id,
                                            true
                                          )}
                                        >
                                          {acoes.map((acao) => (
                                            <option key={acao} value={acao}>{acao}</option>
                                          ))}
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={subverificacao.responsavel || ''}
                                          onChange={(e) => handleResponsavelChange(
                                            subverificacao._id,
                                            e.target.value,
                                            fase._id,
                                            subetapa._id,
                                            verificacao._id,
                                            true
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="date"
                                          value={subverificacao.dataPrevista ? 
                                            new Date(subverificacao.dataPrevista).toISOString().split('T')[0] : 
                                            ''
                                          }
                                          onChange={(e) => handleDataChange(
                                            subverificacao._id,
                                            e.target.value,
                                            fase._id,
                                            subetapa._id,
                                            verificacao._id,
                                            true
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <select
                                          value={subverificacao.status}
                                          onChange={(e) => handleStatusChange(
                                            subverificacao._id,
                                            e.target.value,
                                            fase._id,
                                            subetapa._id,
                                            verificacao._id,
                                            true
                                          )}
                                        >
                                          <option value="Não iniciado">Não iniciado</option>
                                          <option value="Em andamento">Em andamento</option>
                                          <option value="Paralisado">Paralisado</option>
                                          <option value="Concluído">Concluído</option>
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="100"
                                          value={subverificacao.porcentagem}
                                          onChange={(e) => handlePorcentagemChange(
                                            subverificacao._id,
                                            e.target.value,
                                            fase._id,
                                            subetapa._id,
                                            verificacao._id,
                                            true
                                          )}
                                        />%
                                      </td>
                                      <td>
                                        <div className="acoes-buttons">
                                          <button onClick={() => handleEditar(
                                            'subverificacao',
                                            subverificacao._id,
                                            subverificacao.nome,
                                            { faseId: fase._id, subetapaId: subetapa._id, verificacaoId: verificacao._id }
                                          )}>
                                            Editar
                                          </button>
                                          <button
                                            onClick={() => handleExcluirSubverificacao(
                                              fase._id,
                                              subetapa._id,
                                              verificacao._id,
                                              subverificacao._id
                                            )}
                                            className="excluir"
                                          >
                                            Excluir
                                          </button>
                                          <button
                                            onClick={() => handleObservacoesClick(
                                              subverificacao,
                                              fase._id,
                                              subetapa._id,
                                              verificacao._id
                                            )}
                                          >
                                            Observações
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </React.Fragment>
                              ))}
                              <tr>
                                <td colSpan="10">
                                  <div className="adicionar-verificacao">
                                    <input
                                      type="text"
                                      value={novoItem}
                                      onChange={(e) => setNovoItem(e.target.value)}
                                      placeholder="Nome da nova verificação"
                                    />
                                    <button onClick={() => handleAdicionarItem('verificacao', { faseId: fase._id, subetapaId: subetapa._id })}>
                                      Adicionar Verificação
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {editando && (
        <div className="modal">
          <div className="modal-content">
            <h3>Editar {editando.tipo}</h3>
            <input
              type="text"
              value={editando.nome}
              onChange={(e) => setEditando({...editando, nome: e.target.value})}
            />
            <button onClick={handleSalvarEdicao}>Salvar</button>
            <button onClick={() => setEditando(null)}>Cancelar</button>
          </div>
        </div>
      )}

      <ObservacoesSidebar
        isOpen={sidebarOpen}
        atividade={verificacaoSelecionada}
        onClose={() => setSidebarOpen(false)}
        onSave={handleSaveObservacoes}
      />
    </div>
  );
}

export default ChecklistVerificacao;
